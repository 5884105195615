.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}

.summary__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
}

.summary__data {
  text-align: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.summary__description {
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.summary__info {
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* Align items vertically center */
  margin-bottom: var(--mb-2-5);
  flex-wrap: wrap;
  width: 100%;
}

.summary__info-item {
  flex: 1;
  margin: 0.5rem;
  text-align: center;
  max-width: 300px;
  min-width: 100px; /* Ensure items don't get too small */
}

.summary__info-title {
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  display: block;
  text-align: center;
}

.summary__info-name {
  font-size: var(--smaller-font-size);
  display: block;
  text-align: center;
}

.summary__buttons {
  display: flex;
  justify-content: center;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  background-color: #89cff0;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: #45b3e7;
  transform: translateY(-2px);
}

.button__icon {
  margin-left: 0.5rem;
}

@media screen and (min-width: 768px) {
  .summary__container {
    gap: 2rem;
  }

  .summary__data {
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .summary {
    padding: 4rem 0;
  }

  .summary__description {
    font-size: var(--normal-font-size);
  }

  .summary__info-title {
    font-size: var(--h1-font-size);
  }

  .summary__info-name {
    font-size: var(--normal-font-size);
  }

  .summary__buttons {
    margin-top: var(--mb-2);
  }
}

/* Adjustments for screens smaller than 860px */
@media screen and (max-width: 860px) {
  .summary__container {
    padding: 0 1rem;
  }

  .summary__info {
    justify-content: space-evenly;
  }

  .summary__info-item {
    flex: 1;
    max-width: 200px;
    text-align: center;
  }

  .summary__info-title {
    font-size: 1rem;
  }

  .summary__info-name {
    font-size: 0.875rem;
  }
}
