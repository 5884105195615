.portfolio {
  padding: 2rem 0;
  text-align: center;
}

.section-title {
  font-size: var(--h2-font-size); /* Match font size with summary section */
  margin-bottom: 1rem;
  color: #333;
}

.section-subtitle {
  display: block;
  font-size: var(--normal-font-size); /* Match font size with summary section */
  margin-bottom: 2rem;
  color: var(--primary-color);
}

.portfolio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.swiper {
  width: 80%; /* Adjust width as necessary */
  max-width: 1200px; /* Ensure it doesn't get too wide */
  margin: 0 auto; /* Center the swiper container */
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-item {
  display: flex;
  flex-direction: column; /* Default to column for mobile-first approach */
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 2rem;
  transition: transform 0.3s ease;
  width: 100%;
}

.portfolio-content {
  display: flex;
  flex-direction: column; /* Default to column for mobile-first approach */
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left; /* Left-align text for smaller screens */
}

.portfolio-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.portfolio-text {
  width: 100%;
  padding: 0; /* Remove padding for smaller screens */
}

.portfolio-title {
  font-size: 1.25rem; /* Adjust font size for smaller screens */
  margin-bottom: 0.5rem;
  color: #333;
}

.portfolio-description {
  font-size: 0.875rem; /* Adjust font size for smaller screens */
  margin-bottom: 1rem;
  color: #666;
}

.portfolio-button {
  display: inline-block;
  font-size: 0.875rem; /* Adjust font size */
  color: #fff;
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.portfolio-button:hover {
  background-color: #45b3e7;
}

.button-icon {
  margin-left: 0.5rem;
}

/* Navigation Arrows */
.swiper-button-next,
.swiper-button-prev {
  color: var(--primary-color);
  transition: color 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem; /* Smaller arrow size */
  width: 20px; /* Smaller arrow width */
  height: 20px; /* Smaller arrow height */
  z-index: 10; /* Ensure arrows are on top */
  position: absolute; /* Ensure proper positioning */
}

.swiper-button-next {
  right: 5px; /* Closer to the edge */
}

.swiper-button-prev {
  left: 5px; /* Closer to the edge */
}

/* Pagination Bullets */
.swiper-pagination-bullet {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color 0.3s ease;
}

.swiper-pagination-bullet-active {
  background-color: #0056b3;
}

/* Ensure Swiper container height */
.swiper-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 464px) and (max-width: 768px) {
  .portfolio-img {
    max-width: 300px; /* Adjust image size for medium screens */
  }
}

@media screen and (min-width: 768px) {
  .section-title {
    font-size: var(--h1-font-size); /* Match font size with summary section */
  }

  .section-subtitle {
    font-size: var(
      --normal-font-size
    ); /* Match font size with summary section */
  }

  .portfolio-item {
    flex-direction: row; /* Switch to row for larger screens */
  }

  .portfolio-content {
    flex-direction: row; /* Switch to row for larger screens */
    text-align: left; /* Left-align text for larger screens */
  }

  .portfolio-img {
    width: 45%;
    margin-bottom: 0; /* Remove bottom margin */
  }

  .portfolio-text {
    width: 45%;
    padding-left: 2rem; /* Add padding to separate text from image */
  }

  .portfolio-title {
    font-size: 1.25rem; /* Adjust font size for larger screens */
  }

  .portfolio-description {
    font-size: 1rem; /* Adjust font size for larger screens */
  }

  .swiper-button-next {
    right: 15px; /* Closer to the edge */
  }

  .swiper-button-prev {
    left: 15px; /* Closer to the edge */
  }
}

@media screen and (min-width: 1024px) {
  .portfolio-description {
    font-size: 1.25rem; /* Adjust font size for larger screens */
  }
}
