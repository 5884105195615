/* Navbar */
.navbar {
  background-color: #fff;
  padding: 30px 20px;
  color: #070707;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the navbar items */
  padding-bottom: 35px;
}

.navbar-logo {
  position: absolute;
  padding-top: 10px;
  left: 20px; /* Adjust left position as needed */
}

.logo-img {
  height: 70px; /* Adjust size as needed */
}

.navbar-list {
  display: flex;
  justify-content: center;
  gap: 10vw;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

.navbar-item {
  list-style: none;
}

.navbar-link {
  color: #070707;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #f4f4f4;
}

body {
  padding-top: 60px; /* Adjust to prevent content from being hidden under the navbar */
}

html {
  scroll-behavior: smooth;
}

section {
  scroll-margin-top: 50px; /* This adds margin for the scroll position */
}

/* Media Queries for screens between 360px and 500px */
@media screen and (max-width: 500px) and (min-width: 361px) {
  .navbar {
    padding: 15px 10px;
    justify-content: flex-start; /* Align items to the start */
    padding-bottom: 10px;
  }

  .navbar-logo {
    position: relative;
    padding-top: 0;
    left: 10px; /* Adjust left position as needed */
  }

  .logo-img {
    height: 60px; /* Adjust size as needed */
  }

  .navbar-list {
    flex-grow: 1; /* Allow the list to grow to fill available space */
    display: flex;
    justify-content: flex-start; /* Align links closer to the logo */
    gap: 2vw; /* Reduce gap between links */
    flex-wrap: nowrap; /* Prevent wrapping */
    margin-left: 10vw;
  }

  .navbar-item {
    margin: 0; /* Reset margin */
  }

  .navbar-link {
    font-size: 1rem; /* Adjust font size to fit links */
  }

  body {
    padding-top: 80px; /* Adjust to prevent content from being hidden under the navbar */
  }
}

/* Media Queries for screens 360px and below */
@media screen and (max-width: 360px) {
  .navbar {
    padding: 15px 10px;
    justify-content: flex-start; /* Align items to the start */
    padding-bottom: 10px;
  }

  .navbar-logo {
    position: relative;
    padding-top: 0;
    left: 0px; /* Adjust left position as needed */
  }

  .logo-img {
    height: 50px; /* Adjust size as needed */
  }

  .navbar-list {
    flex-grow: 1; /* Allow the list to grow to fill available space */
    display: flex;
    justify-content: flex-start; /* Align links closer to the logo */
    gap: 2vw; /* Reduce gap between links */
    flex-wrap: nowrap; /* Prevent wrapping */
    margin-left: 5vw;
  }

  .navbar-item {
    margin: 0; /* Reset margin */
  }

  .navbar-link {
    font-size: 0.9rem; /* Adjust font size to fit links */
  }

  body {
    padding-top: 80px; /* Adjust to prevent content from being hidden under the navbar */
  }
}
