/* Footer.css */
.footer {
  background-color: #89cff0;
  padding: 2rem 1rem;
  color: #fff;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-brand {
  margin-bottom: 1rem;
}

.footer-title {
  font-size: 2rem;
  font-weight: 600;
}

.footer-subtitle {
  font-size: 1rem;
  font-weight: 400;
}

.footer-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin-bottom: 1rem;
}

.footer-links li {
  font-size: 1rem;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: #f0f0f0;
}

.footer-social {
  display: flex;
  gap: 1rem;
}

.footer-social a {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: #f0f0f0;
}

.footer-bottom {
  margin-top: 1rem;
  font-size: 0.875rem;
}
