/* Projects.css */
.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;
  width: 100%;
  box-sizing: border-box;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.search {
  margin-bottom: 1.5rem;
}

.search-input {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  max-width: 100%;
}

.projects-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: 70vh; /* Adjust this height to show the first two projects properly */
  overflow-y: auto;
  align-items: center;
  max-width: 100%;
  box-sizing: border-box;
}

.project-item {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for shadow */
  padding: 1rem; /* Ensure padding for better readability */
  flex: 0 0 45%; /* Ensure each project item takes up 45% of the container height */
  max-height: 45%;
  max-width: 750px;
  width: 100%;
  box-sizing: border-box;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}

.project-content {
  padding: 1rem;
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.project-date {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
  text-align: left;
  padding-left: 1.5rem;
  list-style: disc;
}

.project-description li {
  margin-bottom: 0.5rem;
}

/* Scrollbar styles */
.projects-container::-webkit-scrollbar {
  width: 8px;
}

.projects-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .section-title {
    font-size: 1.5rem;
  }

  .search-input {
    width: 100%;
    font-size: 0.875rem;
  }

  .project-title {
    font-size: 1.25rem;
  }

  .project-date,
  .project-description {
    font-size: 0.7rem;
  }

  .project-item {
    padding: 0.75rem;
  }

  .projects-container {
    max-height: calc(60vh - 4rem); /* Allow full height for mobile */
  }
}
