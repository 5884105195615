/* Main Content */
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the contents horizontally */
  justify-content: center; /* Center the contents vertically */
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box; /* Ensure padding is included in the total width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.content-section {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box; /* Ensure padding is included in the total width */
}
