.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem 0;
}

.skills__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: flex-start;
  justify-content: center;
}

.skills__group {
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  flex: 1;
  min-width: 200px;
  max-width: 300px;
}

.skills__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  margin-bottom: var(--mb-1);
  position: relative;
  white-space: nowrap;
}

.skills__title::after {
  content: "▼";
  font-size: 0.8rem;
  margin-left: 10px;
  transition: transform 0.5s ease;
}

.skills__group.open .skills__title::after {
  transform: rotate(180deg);
}

.skills__item {
  font-size: var(--normal-font-size);
  margin-bottom: var(--mb-0-5);
  padding-left: 1rem;
  opacity: 0;
  transform: translateY(-10px);
  animation: fadeIn 0.5s forwards;
  animation-delay: var(--delay); /* Use a custom property for delay */
  visibility: hidden;
}

.skills__group ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.7s ease, padding 0.7s ease;
}

.skills__group.open ul {
  max-height: 1000px;
  padding-top: 0.5rem;
}

.skills__group.open .skills__item {
  visibility: visible;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
