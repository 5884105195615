/* About Section */
#about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  gap: 50px;
}

.about-image {
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
  margin-top: 25px;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-content p {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-content p i {
  margin-right: 10px;
  color: #333333;
}

/* Responsive Design */
@media (min-width: 768px) {
  .about-container {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .about-image {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .about-content {
    align-items: flex-start;
    text-align: left;
  }

  .about-content p {
    justify-content: flex-start;
  }
}
