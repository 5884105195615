/* App.css */

/*==================== GOOGLE FONTS ====================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import "./Navbar.css";
@import "./About.css";
@import "./Skills.css";
@import "./Projects.css";
@import "./Portfolio.css";

/*==================== VARIABLES CSS ====================*/
:root {
  --header-height: 3rem;
  --primary-color: #89cff0; /* New primary color */
  --first-color: var(--primary-color);
  --first-color-second: hsl(var(--primary-color), 69%, 61%);
  --first-color-alt: hsl(var(--primary-color), 57%, 53%);
  --first-color-lighter: hsl(var(--primary-color), 92%, 85%);
  --title-color: hsl(250, 8%, 15%);
  --text-color: hsl(250, 8%, 45%);
  --text-color-light: hsl(250, 8%, 65%);
  --input-color: hsl(250, 70%, 96%);
  --body-color: hsl(250, 60%, 99%);
  --container-color: #fff;
  --scroll-bar-color: hsl(250, 12%, 90%);
  --scroll-thumb-color: hsl(250, 12%, 80%);
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;
  --font-medium: 500;
  --font-semi-bold: 600;
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

body {
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--primary-color); /* Links color */
}

a:hover {
  color: var(--first-color-alt); /* Links hover color */
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--mb-1-5);
  padding-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding: 2rem 0 4rem;
  text-align: center;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
  color: var(--primary-color); /* Subtitle color */
}

button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: var(--mb-0-5) var(--mb-1);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover,
input[type="submit"]:hover,
input[type="button"]:hover,
input[type="reset"]:hover {
  background-color: var(--first-color-alt);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: var(--scroll-bar-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb-color);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-color-light);
}

.animate__animated.animate__fadeInUp {
  --animate-duration: 1.75s;
}

.animate__animated.animate__fadeInLeft {
  --animate-duration: 1.75s;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 1.75s;
}
