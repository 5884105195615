/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

/* Base styles */
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

img {
  max-width: 100%;
  height: auto;
}

/* Responsive Design */

/* Larger screens */
@media (min-width: 1024px) {
  .main-content {
    flex-direction: row; /* side-by-side layout for larger screens */
  }

  .left-column,
  .right-column {
    width: auto; /* Let flexbox handle the width */
  }
}

/* Smaller screens */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column; /* stack on top of each other for smaller screens */
  }

  .left-column,
  .right-column {
    width: 100%; /* each column takes full width on smaller screens */
  }

  .row {
    flex-direction: column; /* Stack the elements on top of each other */
    align-items: flex-start; /* Align to the start of the flex container */
  }

  .col-8,
  .col-4 {
    width: 100%; /* Full width for small screens */
    text-align: left; /* Align text to the left */
    padding-bottom: 5px; /* Smaller padding for a more compact layout */
  }

  .col-4 {
    text-align: right; /* Align the date to the right */
    padding-top: 5px; /* Add some space above the date */
  }
}

/* Very small screens */
@media (max-width: 480px) {
  .search input {
    width: 100%;
    margin: 0.5rem 0;
    font-size: 0.9rem;
    padding: 0.5rem;
    border-radius: 1rem;
    border: 1px solid #eeeeee;
  }
}
